<template>
  <div class="practise">
    <div class="practise-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>题库练习</el-breadcrumb-item>
            <el-breadcrumb-item>{{exerciseType == 'sequence' ? '顺序练习' : exerciseType == 'random' ? '随机练习' : exerciseType == 'chapter' ? '章节练习' : exerciseType == 'special' ? '专项练习' : '难题练习'}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="examination">
          <!-- 练习类型 -->
          <div class="exam-type">
            <div class="type-title">
              <p class="title-line"></p>
              <p class="title-name">{{exerciseType == 'sequence' ? '顺序练习' : exerciseType == 'random' ? '随机练习' : exerciseType == 'chapter' ? '章节练习' : exerciseType == 'special' ? '专项练习' : '难题练习'}}</p>
            </div>
            <div class="car">
              <p class="car-type">车型：{{carType == 'car' ? '小车' : carType == 'bus' ? '客车' : '货车'}}</p>
              <p class="car-suject">科目：{{stepType == 1 ? '科目一' : '科目四'}}</p>
            </div>
          </div>
          <div v-if="idMockExamDataList.length > 0">
            <!-- 练习题目 -->
            <div class="exam-topic" v-if="currentMockExamData">
              <div class="topic-vessel">
                <div class="left-vessel">
                  <div class="topic-title">
                    <p class="topic-num">{{currentTopic + 1}}/{{idMockExamDataList.length}}、</p>
                    <p class="topic-name">{{currentMockExamData.title}}</p>
                  </div>
                  <div class="topic-options">
                    <el-radio-group
                      v-if="currentMockExamData.types == 1"
                      v-model="currentMockExamData.userAnswer"
                      @change="radioExamChange"
                    >
                      <el-radio :class="{'radio-error': currentMockExamData.userAnswer && currentMockExamData.answer !== 'A'}" label="A" v-if="currentMockExamData.optiona"
                        >A、{{ currentMockExamData.optiona }}</el-radio
                      >
                      <el-radio :class="{'radio-error': currentMockExamData.userAnswer && currentMockExamData.answer !== 'B'}" label="B" v-if="currentMockExamData.optionb"
                        >B、{{ currentMockExamData.optionb }}</el-radio
                      >
                      <el-radio :class="{'radio-error': currentMockExamData.userAnswer && currentMockExamData.answer !== 'C'}" label="C" v-if="currentMockExamData.optionc"
                        >C、{{ currentMockExamData.optionc }}</el-radio
                      >
                      <el-radio :class="{'radio-error': currentMockExamData.userAnswer && currentMockExamData.answer !== 'D'}" label="D" v-if="currentMockExamData.optiond"
                        >D、{{ currentMockExamData.optiond }}</el-radio
                      >
                    </el-radio-group>
                    <el-checkbox-group
                      v-if="currentMockExamData.types == 2"
                      v-model="currentMockExamData.userAnswerList"
                      @change="checkExamChange"
                    >
                      <el-checkbox :class="{'checkbox-error': currentMockExamData.userAnswer && currentMockExamData.answer.indexOf('A') < 0}" label="A" v-if="currentMockExamData.optiona"
                        >A、{{ currentMockExamData.optiona }}</el-checkbox
                      >
                      <el-checkbox :class="{'checkbox-error': currentMockExamData.userAnswer && currentMockExamData.answer.indexOf('B') < 0}" label="B" v-if="currentMockExamData.optionb"
                        >B、{{ currentMockExamData.optionb }}</el-checkbox
                      >
                      <el-checkbox :class="{'checkbox-error': currentMockExamData.userAnswer && currentMockExamData.answer.indexOf('C') < 0}" label="C" v-if="currentMockExamData.optionc"
                        >C、{{ currentMockExamData.optionc }}</el-checkbox
                      >
                      <el-checkbox :class="{'checkbox-error': currentMockExamData.userAnswer && currentMockExamData.answer.indexOf('D') < 0}" label="D" v-if="currentMockExamData.optiond"
                        >D、{{ currentMockExamData.optiond }}</el-checkbox
                      >
                    </el-checkbox-group>
                    <el-radio-group
                      v-if="currentMockExamData.types == 3"
                      v-model="currentMockExamData.userAnswer"
                      @change="radioExamChange"
                    >
                      <el-radio :class="{'radio-error': currentMockExamData.userAnswer && currentMockExamData.answer !== 'true'}" label="true" v-if="currentMockExamData.optiona">{{
                        currentMockExamData.optiona
                      }}</el-radio>
                      <el-radio
                        :class="{'radio-error': currentMockExamData.userAnswer && currentMockExamData.answer !== 'false'}"
                        label="false"
                        v-if="currentMockExamData.optionb"
                        >{{ currentMockExamData.optionb }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                </div>
                <div class="right-vessel">
                  <div
                    class="photo"
                    v-if="
                      currentMockExamData.icon && currentMockExamData.icon.substring(
                        currentMockExamData.icon.length - 4
                      ) != 'null'
                    "
                  >
                    <img
                      v-show="
                        currentMockExamData.icon.substring(
                          currentMockExamData.icon.length - 3
                        ) != 'mp4'
                      "
                      class="photo-img"
                      :src="currentMockExamData.icon"
                    />
                    <video
                      v-show="
                        currentMockExamData.icon.substring(
                          currentMockExamData.icon.length - 3
                        ) == 'mp4'
                      "
                      class="photo-img"
                      :src="currentMockExamData.icon"
                      autoplay
                      controls
                      controlslist="nodownload nofullscreen noremoteplayback"
                    ></video>
                  </div>
                </div>
              </div>
              <div class="topic-note">{{
                    currentMockExamData.types == 1
                      ? "单选题"
                      : currentMockExamData.types == 2
                      ? "多选题"
                      : "判断题"
                  }}，请选择你认为正确的答案！</div>
              <div class="topic-operate">
                <div class="operate-choose">
                  <p :class="['choose', { disabled: currentTopic == 0 }]"
                    @click="tapPreviousQuestion"
                  >上一题</p>
                  <p :class="['choose',{ disabled: currentTopic == idMockExamDataList.length - 1 }]"
                    @click="tapNextQuestion"
                  >下一题</p>
                </div>
                <div class="operate-show">
                  <p :class="['show', {'detail' : !isExamDetail}]" @click="isExamDetail = !isExamDetail">显示详解</p>
                  <p :class="['show', {'detail' : !isAnswer}]" @click="isAnswer = !isAnswer">显示答题卡</p>
                </div>
              </div>
              <div class="topic-auto">
                <div class="auto-choose">
                  <el-checkbox v-model="isAutoNext">答对自动下一题</el-checkbox>
                </div>
                <div class="completions">
                  <div class="outcome">
                    <p class="outcome-label">答对: </p>
                    <p class="outcome-upshot"> {{proper}}题</p>
                  </div>
                  <div class="outcome">
                    <p class="outcome-label">答错: </p>
                    <p class="outcome-upshot"> {{mistake}}题</p>
                  </div>
                  <div class="outcome">
                    <p class="outcome-label">正确率: </p>
                    <p class="outcome-upshot"> {{score}}%</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 显示答题卡 -->
            <div class="exam-answersheet" v-show="isAnswer">
              <ul class="answer-sheet">
                <!-- <li class="sheet-li mistake">1</li>
                <li class="sheet-li proper">2</li>
                <li class="sheet-li correct">3</li> -->
                <li
                  :class="[
                    'sheet-li',
                    { correct: currentTopic == index },
                    {
                      proper: item.userAnswer && item.answer == item.userAnswer,
                    },
                    {
                      mistake:
                        item.userAnswer && item.answer != item.userAnswer,
                    },
                  ]"
                  v-for="(item, index) in mockExamDataList"
                  :key="item.id"
                  @click="tapCurrentTopicBtn(index)"
                >
                  {{ index + 1 }}
                </li>
              </ul>
            </div>
            <!-- 试题详解 -->
            <div class="exam-analyze" v-if="currentMockExamData && isExamDetail">
              <div class="analyze-title">试题详解</div>
              <div class="analyze-con">
                <p class="con-note">官方解析</p>
                <p class="con-detail">{{currentMockExamData.resolving}}</p>
              </div>
            </div>
          </div>
          <el-empty v-else description="暂无任何题目"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryGetQuestionIdList, queryGetQuestionById } from "@/api/herbalMedicine";

export default {
  name: 'PractisePage',
  data () {
    return {
      // 是否已经提示最后一题
      isRemindEnd: false, 
      // 是否显示题目详情
      isExamDetail: false,
      // 是否显示答题卡
      isAnswer: true,
      // 是否答对自动下一题
      isAutoNext: false,
      // 练习类型
      exerciseType: 'sequence',
      // 车型
      carType: "car",
      // 科目
      stepType: 1,
      // 是否是顺序练习
      isOrder: true,
      // 所选章节
      chapter: 0,
      // 题目难易程度
      hardLevel: 1,
      // 专项练习
      knowType: 0,
      // 所有考试题目
      mockExamDataList: [],
      // 所有考试题目ID列表
      idMockExamDataList: [],
      // 当前题目
      currentTopic: 0,
      // 当前题目详细数据
      currentMockExamData: null,
      // 正确题目个数
      proper: 0,
      // 错误题目个数
      mistake: 0,
      // 正确率
      score: 0,
    }
  },
  computed: {
  },
  created () {
    this.carType =
      this.$route.query.carType == "trolley"
        ? "car"
        : this.$route.query.carType;
    this.stepType = this.$route.query.stepType == "branchOne" ? 1 : 4;
    this.isOrder = this.$route.query.isOrder == "order" ? true : false;
    this.exerciseType = this.$route.query.exerciseType;
    this.chapter = this.$route.query.chapter ? this.$route.query.chapter : 0
    this.hardLevel = this.$route.query.hardLevel ? this.$route.query.hardLevel : 1
    this.knowType = this.$route.query.knowType ? this.$route.query.knowType : 0
    this.getQueryGetQuestionIdList()
  },
  methods: {
    // 消息提示框（最后一道题提示）
    openMessage() {
      this.$alert('已经到达最后一题', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          this.isRemindEnd = true
        }
      });
    },
    // 当前选择题目的绑定值变更类型
    changeCurUserAnswer(obj) {
      let _obj = JSON.parse(JSON.stringify(obj));
      if (_obj) {
        for (let key in _obj) {
          // console.log(key + '---' + obj[key])
          if (_obj["types"] == 2) {
            _obj["userAnswerList"] = _obj["userAnswer"]
              ? _obj["userAnswer"].split("")
              : [];
          }
        }
        this.currentMockExamData = _obj;
      }
    },
    // 获取所有考试题ID
    getQueryGetQuestionIdList() {
      let date = {
        carType: this.carType,
        stepType: this.stepType,
        isOrder: this.isOrder
      }
      if (this.chapter != 0) date = Object.assign(date , {
        chapter: this.chapter
      })
      if (this.hardLevel != 1) date = Object.assign(date , {
        hardLevel: this.hardLevel
      })
      if (this.knowType != 0) date = Object.assign(date , {
        knowType: this.knowType
      })
      queryGetQuestionIdList(date).then((res) => {
        if (res.data.length > 0) {
          this.idMockExamDataList = res.data
          this.getQueryGetQuestionById(res.data[this.currentTopic])
        }
      });
    },
    // 通过id获取题目
    getQueryGetQuestionById(cid) {
      queryGetQuestionById({
        id: cid
      }).then((res) => {
        if (res.data) {
          this.changeCurUserAnswer(res.data)
          this.mockExamDataList[this.currentTopic] = res.data
        }
      });
    },
    // 单选题判断题改变触发
    radioExamChange(value) {
      this.mockExamDataList[this.currentTopic] = this.currentMockExamData
      this.submitExam()
      if ((this.currentTopic + 1) == this.idMockExamDataList.length && !this.isRemindEnd) {
        this.openMessage()
      }
      // 答对自动下一题
      if (this.isAutoNext && this.currentMockExamData.answer == value) {
        this.tapNextQuestion()
      }
    },
    // 多选题改变触发
    checkExamChange(value) {
      value.sort(function (a, b) {
        return a.localeCompare(b);
      });
      let _result = value.join("");
      this.currentMockExamData["userAnswer"] = _result;
      this.mockExamDataList[this.currentTopic] = this.currentMockExamData
      this.submitExam()
      if ((this.currentTopic + 1) == this.idMockExamDataList.length && !this.isRemindEnd) {
        this.openMessage()
      }
      // 答对自动下一题
      if (this.isAutoNext && this.currentMockExamData.answer == _result) {
        this.tapNextQuestion()
      }
    },
    // 触发上一题
    tapPreviousQuestion() {
      if (this.currentTopic == 0) return;
      this.currentTopic--;
      this.changeCurUserAnswer(this.mockExamDataList[this.currentTopic]);
    },
    // 触发下一题
    tapNextQuestion() {
      if (this.currentTopic == this.idMockExamDataList.length - 1) return;
      this.currentTopic++;
      if (this.currentTopic < this.mockExamDataList.length) {
        this.currentMockExamData = this.mockExamDataList[this.currentTopic]
      } else {
        this.getQueryGetQuestionById(this.idMockExamDataList[this.currentTopic])
      }
    },
    // 点击答题卡触发
    tapCurrentTopicBtn(index) {
      this.currentTopic = index;
      this.changeCurUserAnswer(this.mockExamDataList[this.currentTopic]);
    },
    submitExam() {
      let proper = 0,mistake = 0,total = 0, score = 0
      let _mockExamDataList = this.mockExamDataList
      _mockExamDataList.map(item => {
        if (item.userAnswer && item.userAnswer == item.answer) {
          proper ++
        } else if (item.userAnswer && item.userAnswer != item.answer) {
          mistake ++
        }
      })
      total = this.idMockExamDataList.length
      score = Math.round(proper / total * 10000) / 100.00
      this.proper = proper
      this.mistake = mistake
      this.score = score
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
